<template>
  <div class="right-menu shipping-right">
    
    <subHeader pageName="HEADER NAVIGATION"/>
    
    <tutorialsAction />

    <div class="db_top">
      <ValidationObserver ref="headerObserver">
        <div class="db-container">
          <div class="viewOrderBox navBox" ref="viewOrderBox">
            <div class="menuSideBar">
              <h4>Pages</h4>
              <!-- add pages block starts here -->
              <div class="pageSelect" id="pagesBox">
                <ul v-for="(page,index) in pages" :key="index">
                  <li>
                    <input 
                      type="checkbox" 
                      name="home" 
                      :id="page.text" 
                      :value="page" 
                      v-model="selectedPages"
                      :disabled="tourIsActive"
                    >
                    <label :for="page.text">{{page.text}}</label>
                  </li>
                </ul>
              </div>
              <div class="selectBtn" style="margin-bottom: 20px;">
                <button @click.prevent="addPage()" id="pages-save-btn"  :disabled="tourIsActive">
                  Add Selected Pages
                </button>
              </div>
              <!-- add pages block ends here -->

              <!-- add categories block starts here -->
              <h4>Categories</h4>
              <div class="pageSelect" id="categoriesBox" ref="categoriesBox">
                <ul v-if="categories.loading && categories.list.length == 0">
                  <li v-for="i in 5" :key="`category_loader-${i}`">
                    <vue-skeleton-loader
                      type="rect"
                      :width="250"
                      :height="20"
                      animation="fade"
                    />
                  </li>
                </ul>
                <ul v-for="(category,index) in categories.list" :key="index" >
                  <li>
                    <input 
                      type="checkbox" 
                      name="home1" 
                      :id="category.id" 
                      :value="category" 
                      v-model="selectedCategoryPages" 
                      :disabled="category.isActive == 0 || tourIsActive"
                    >
                    <label :for="category.id">
                      {{category.text.length > 30 ? category.text.substr(0,30) + "..." : category.text }} {{ category.isActive == 0 ? '(Inactive)' : '' }}
                    </label>
                  </li>
                </ul>
                <ul v-if="categories.loading && categories.list.length != 0">
                  <li>
                    <vue-skeleton-loader
                      type="rect"
                      :width="250"
                      :height="20"
                      animation="fade"
                    />
                  </li>
                </ul>
              </div>
              <div class="selectBtn" style="margin-bottom: 20px;">
                <button @click.prevent="addCategoryPage()" id="category-save-btn" :disabled="tourIsActive">
                  Add Selected Categories
                </button>
              </div>
              <!-- add categories block ends here -->

              <!-- add custom link block starts here -->
              <h4>Custom Link</h4>
              <form @submit.prevent="addCustomLink">
                <div class="pageSelect ctmLinkBox">
                  <label for="linkText">Label Text</label>
                  <input 
                    type="text" 
                    name="linkText" 
                    id="linkText"
                    placeholder="Write a label text"
                    v-model="customLinkText" 
                    style="margin-bottom: 10px"
                    :disabled="tourIsActive"
                  >
                  <label for="url">URL</label>
                  <input 
                    type="text" 
                    name="url" 
                    id="url" 
                    v-model="customLinkUrl" 
                    placeholder="Write a link URL (http://)"
                    :disabled="tourIsActive"
                  >
                </div>
                <div class="selectBtn">
                  <button type="submit" id="save-customLink" :disabled="tourIsActive">Add Custom Link</button>
                </div>
              </form>
              <!-- add custom link block ends here -->

            </div>
            
            <!-- navigation block starts here -->
            <div class="box1">
              <div class="nestableLoader" v-if="navigationIsPending">
                <ul>
                  <li v-for="i in 5" :key="`navigation_loader-${i}`">
                    <vue-skeleton-loader
                      type="rect"
                      :width="500"
                      :height="40"
                      animation="fade"
                    />
                  </li>
                </ul>
              </div>
              <vue-nestable :maxDepth="3" v-model="currentNestableItems" cross-list group="cross" v-else>
                <template slot-scope="{ item }">
                  <VueNestableHandle :item="item" >
                    <span>{{ item.text.length > 30 ? item.text.substr(0,30) + "..." : item.text }}</span>
                    <div class="menuActionBtns">
                      <a :href="item.url" target="_blank" v-if="item.isCustomLink && item.url.includes('https://')" @touchstart.stop="handleLinkClick(item)">
                        <svg enable-background="new 0 0 32 32" height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g>
                            <polyline fill="none" points="   649,137.999 675,137.999 675,155.999 661,155.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                            <polyline fill="none" points="   653,155.999 649,155.999 649,141.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                            <polyline fill="none" points="   661,156 653,162 653,156  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                          </g>
                          <g>
                            <g>
                              <path d="M16,25c-4.265,0-8.301-1.807-11.367-5.088c-0.377-0.403-0.355-1.036,0.048-1.413c0.404-0.377,1.036-0.355,1.414,0.048    C8.778,21.419,12.295,23,16,23c4.763,0,9.149-2.605,11.84-7c-2.69-4.395-7.077-7-11.84-7c-4.938,0-9.472,2.801-12.13,7.493    c-0.272,0.481-0.884,0.651-1.363,0.377c-0.481-0.272-0.649-0.882-0.377-1.363C5.147,10.18,10.333,7,16,7    c5.668,0,10.853,3.18,13.87,8.507c0.173,0.306,0.173,0.68,0,0.985C26.853,21.819,21.668,25,16,25z"/>
                            </g>
                            <g>
                              <path d="M16,21c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,21,16,21z M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3    s3-1.346,3-3S17.654,13,16,13z"/>
                            </g>
                          </g>
                        </svg>
                      </a>
                      <a :href="'http://' + settings.domain + item.url" target="_blank" @touchstart.stop="handleLinkClick(item)" v-else>
                        <svg enable-background="new 0 0 32 32" height="32px" id="Layer_1" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <g>
                            <polyline fill="none" points="   649,137.999 675,137.999 675,155.999 661,155.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                            <polyline fill="none" points="   653,155.999 649,155.999 649,141.999  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                            <polyline fill="none" points="   661,156 653,162 653,156  " stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                          </g>
                          <g>
                            <g>
                              <path d="M16,25c-4.265,0-8.301-1.807-11.367-5.088c-0.377-0.403-0.355-1.036,0.048-1.413c0.404-0.377,1.036-0.355,1.414,0.048    C8.778,21.419,12.295,23,16,23c4.763,0,9.149-2.605,11.84-7c-2.69-4.395-7.077-7-11.84-7c-4.938,0-9.472,2.801-12.13,7.493    c-0.272,0.481-0.884,0.651-1.363,0.377c-0.481-0.272-0.649-0.882-0.377-1.363C5.147,10.18,10.333,7,16,7    c5.668,0,10.853,3.18,13.87,8.507c0.173,0.306,0.173,0.68,0,0.985C26.853,21.819,21.668,25,16,25z"/>
                            </g>
                            <g>
                              <path d="M16,21c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S18.757,21,16,21z M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3    s3-1.346,3-3S17.654,13,16,13z"/>
                            </g>
                          </g>
                        </svg>
                      </a>
                      <button 
                        type="button" 
                        class="menuEditBtn" 
                        @click="item.isExpand = !item.isExpand" 
                        @touchstart.stop="item.isExpand = !item.isExpand" 
                        :disabled="tourIsActive"
                      >
                        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 34.5v7.5h7.5l22.13-22.13-7.5-7.5-22.13 22.13zm35.41-20.41c.78-.78.78-2.05 0-2.83l-4.67-4.67c-.78-.78-2.05-.78-2.83 0l-3.66 3.66 7.5 7.5 3.66-3.66z"/>
                          <path d="M0 0h48v48h-48z" fill="none"/>
                        </svg>
                      </button>
                      <button type="button" class="menuEditBtn" @click="removeListItem(item)" @touchstart.stop="removeListItem(item)" :disabled="tourIsActive">
                        <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z"/>
                          <path d="M0 0h48v48H0z" fill="none"/>
                        </svg>
                      </button>
                    </div>
                  </VueNestableHandle>
                  <div :class="item.isExpand ? 'menuDropBox activeDropMenu' : 'menuDropBox'">
                    <div class="menuBorderBox">
                      <div class="navOptions">
                        <ValidationProvider name="label" rules="required" :vid="`label_`+item.id" v-slot="{ errors }">
                          <label :for="`navTxt-${item.id}`">Nav Label</label>
                          <input 
                            type="text" 
                            placeholder="Write a navigation label" 
                            :name="`navTxt-${item.id}`" 
                            :id="`navTxt-${item.id}`" 
                            v-model="item.text" 
                            :disabled="tourIsActive"
                          >
                          <span class="text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="navOptions">
                        <ValidationProvider :vid="`url_`+item.id" name="social media link" :rules="{required:true,regex:'(^https?://)|(^/)'}" v-slot="{ errors }">
                          <label :for="`navUrl-${item.id}`">URL</label>
                          <input 
                            type="text" 
                            :name="`navUrl-${item.id}`" 
                            :id="`navUrl-${item.id}`" 
                            placeholder="Write a navigation URL (https:// or /)" 
                            v-model="item.url" 
                            :disabled="!item.isCustomLink || tourIsActive"
                          >
                          <span class="text-danger" v-if="errors">{{ errors[0] ? errors[0] + ' ' + '(must start with "https://" or "/") ' :'' }} </span>
                        </ValidationProvider>
                      </div>
                      <div class="navOptions moreOptions">
                        <div class="newTab">
                          <input type="checkbox" name="tab" id="tab" v-model="item.isOpenNewTab" :disabled="tourIsActive">
                          <label for="tab">Open in new tab</label>
                        </div>
                        <div class="indexedNav" v-if="item.isPage">
                          <input type="checkbox" name="indexedOption" id="indexedOption" v-model="item.isIndexed" :disabled="tourIsActive">
                          <label for="indexedOption">Indexed</label>
                        </div>
                      </div>
                      <div class="saveMenuBtn">
                        <button @click="item.isExpand = !item.isExpand" :disabled="tourIsActive">Save</button>
                      </div>
                    </div>
                  </div>
                </template>
              </vue-nestable>
            </div>
            <!-- navigation block ends here -->

          </div>

          <!-- save navigation block starts here -->
          <div class="saveMenuBtn">
            <loaderBtn v-if="saveIsLoading"/>
            <button v-else @click.prevent="validate" id="save-menu-btn" :disabled="tourIsActive">Save Menu</button>
          </div>
          <!-- save navigation block starts here -->
           
        </div>
      </ValidationObserver>
    </div>

    <!--vue tour-->
    <v-tour name="headerNavigationTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from 'vue-nestable';
import { MessageBox, Message } from 'element-ui';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
  name: 'HeaderNavigation',
  metaInfo(){
    return {
      title: 'Navigation | Header Navigation | YeetCommerce',
    };
  },
  data(){
    return {
      nestableItems: [],
      currentNestableItems:[],
      pages:[
        {
          id: 0,
          text: 'Home',
          slug:''
        },
        {
          id: 1,
          text: 'Shop',
          slug:'shop'
        },
        {
          id: 2,
          text: 'Collections',
          slug:'collections'
        },
        {
          id: 3,
          text: 'Blogs',
          slug:'blog'
        },
        {
          id: 4,
          text: 'About Us',
          slug:'about-us'
        },
        {
          id: 5,
          text: 'Contact Us',
          slug:'contact-us'
        },
        {
          id: 6,
          text: 'FAQs',
          slug:'faqs'
        },
        {
          id: 7,
          text: 'Privacy Policy',
          slug:'privacy-policy'
        },
        {
          id: 8,
          text: 'Terms & Conditions',
          slug:'terms-and-conditions'
        },
        {
          id: 9,
          text: 'SignUp',
          slug:'signup'
        },
        {
          id: 10,
          text: 'Login',
          slug:'login'
        },
      ],
      categories:{
        list: [],
        currentPage: 0,
        lastPage: 0,
        loading: false,
      },
      customLinkText:'',
      customLinkUrl:'',
      selectedPages:[],
      selectedCategoryPages:[],
      saveIsLoading: false,
      categoryLoading: false,
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
        buttonSkip: 'Skip Tour',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: false,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        //header navigation steps
        {
          target: '#pagesBox',
          content: `<strong>Pages Selection</strong><br>Pages can be selected from the available options.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#pages-save-btn',
          content: `<strong>Add Pages To Menu</strong><br>By clicking this button, All the selected pages can be added to the menu.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '#categoriesBox',
          content: `<strong>Categories Selection</strong><br>Categories can be selected from the available options.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#category-save-btn',
          content: `<strong>Add Categories To Menu</strong><br>By clicking this button, All the selected categories can be added to the menu.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '#linkText',
          content: `<strong>Custom Link Name</strong><br>Custom Link name can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '#url',
          content: `<strong>Custom Link Url</strong><br>Custom Link url can be added using this field.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '#save-customLink',
          content: `<strong>Add Custom Link To Menu</strong><br>By clicking this button, the added custom link details can be added to the menu.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '.box1',
          content: `<strong>Navigation Menu</strong><br>Header navigation menu will be shown here.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
        {
          target: '#save-menu-btn',
          content: `<strong>Save Navigation Menu</strong><br>Navigation menu changes can be saved by clicking this button.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components:{
    VueNestable,
    VueNestableHandle,
    subHeader,
    tutorialsAction,
    loaderBtn
  },
  computed:{
    ...mapGetters({
      settings:'settings_module/settings',
      storeHeaderNavigation:'navigations_module/headerNavigation',
      navigationIsPending:'navigations_module/isPending'
    })
  },
  watch:{
    storeHeaderNavigation:{
      handler:function(value){

        this.nestableItems = value;
        this.currentNestableItems = _.cloneDeep(this.nestableItems);

      },deep:true
    }
  },
  methods:{
    addPage(){
      
      if(this.selectedPages.length > 0){

        let pages = [];

        this.selectedPages.forEach((element, index) => {

          let uuid = uuidv4();

          let slug = element.slug
          slug =  "/" + slug

          let obj = {
            id: uuidv4(),
            text:element.text, 
            children:[],
            uid:uuid,
            url:slug,
            isExpand:false,
            isOpenNewTab:false,
            isPage:true,
            isCategory:false,
            isIndexed:true
          };

          pages.push(obj);

        });

        this.currentNestableItems = [...this.currentNestableItems, ...pages];
        this.selectedPages = [];

        this.$notify({
          type: 'success',
          title: 'Success',
          message: "Page has been successfully added. Save to make it visible to your customers.",
        });

      }

    },
    addCategoryPage(){

      if(this.selectedCategoryPages.length > 0){

        let pages = [];

        this.selectedCategoryPages.forEach((element, index) => {

          let uuid = uuidv4();

          let slug = element.slug
          slug = "/collections/" + slug

          let obj = {
            id: uuidv4(),
            text:element.text, 
            children:[],
            url:slug,
            uid:uuid,
            isExpand:false,
            isOpenNewTab:false,
            isCategory:true,
            isPage:false,
            category_id:element.id,
          };

          pages.push(obj);

        });

        this.currentNestableItems = [...this.currentNestableItems, ...pages];
        this.selectedCategoryPages = [];

        this.$notify({
          type: 'success',
          title: 'Success',
          message: "Category has been successfully added. Save to make it visible to your customers.",
        });

      }

    },
    addCustomLink(){

      if(this.customLinkText.length == 0){

        this.$notify({
          type: 'info',
          title: 'Label Missing',
          message: "Please ensure the label field is filled.",
        });

        return

      }

      if(this.customLinkUrl.length == 0){

        this.$notify({
          type: 'info',
          title: 'URL Missing',
          message: "Please ensure the URL field is filled.",
        });

        return

      }

      if(this.customLinkUrl){
        
        let regx = new RegExp('(^https?://)|(^/)');

        if(!regx.test(this.customLinkUrl)){

          this.$notify({
            type: 'error',
            title: 'Alert',
            message: "Link should start with https:// or /.",
          });

          return

        }

      }

      let uuid = uuidv4();

      let page = [
        {
          children:[],
          id: uuidv4(),
          text: this.customLinkText,
          url:this.customLinkUrl,
          uid:uuid,
          isExpand:false,
          isOpenNewTab:false,
          isCustomLink:true,
          isCategory:false,
          isPage:false,
        }
      ];

      this.currentNestableItems = [...this.currentNestableItems, ...page];
      this.customLinkText = ''
      this.customLinkUrl = ''

      this.$notify({
        type: 'success',
        title: 'Success',
        message: "Custom link has been successfully added. Save to make it visible to your customers.",
      });

    },
    validate(){

      this.$refs.headerObserver.validate().then(success => {

        if(!success){
          
          const errors = Object.entries(this.$refs.headerObserver.errors)
          .map(([key, value]) => ({ key, value })).filter(error => error["value"].length);

          this.$refs.headerObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center"});

          let allErrors = Object.entries(this.$refs.headerObserver.errors).map(([key,value]) => ({ key,value }));

          allErrors.forEach((error)=>{

            let value = null;

            if(error.value.length > 0){

              value = error.key.split("_")

              this.currentNestableItems.forEach(item => {

                if(item.id == value[1]){

                  item.isExpand = true;
                  return 

                }else if(item.children.length > 0){

                  item.children.forEach(childItem => {
                    
                    if(childItem.id == value[1]){

                      childItem.isExpand = true;
                      return

                    }else if(childItem.children.length > 0 ){

                      childItem.children.forEach(grandChildItem => {

                        if(grandChildItem.id == value[1]){

                          grandChildItem.isExpand = true;
                          return

                        }

                      });

                    }

                  });

                }

              });

            }

          });

          this.$notify({
            type: 'error',
            title: 'Validation Failed',
            message: "Check the content to eliminate validation errors.",
          });

          return

        }else{

          this.savePage();

        }

      });

    },
    async savePage(){

      let formData = new FormData();

      formData.append('name','main');
      formData.append('menu',JSON.stringify(this.currentNestableItems));

      this.saveIsLoading = true;
      try{
        let res = await this.$axios.post('/navigation/update',formData);
        if(res.data.status_code == '1051'){
          
          this.$notify({
            type: 'success',
            title: 'Success',
            message: res.data.message,
          });

          let payload = {
            navigationName: 'main',
            headerNav: this.currentNestableItems
          }

          this.$store.commit('navigations_module/update_navigation',payload);

        }
      }catch(error){

        if(error.response){

          this.$message({
            type: 'error',
            showClose: true,
            message: error.response.data.message,
          });

        }else{

          this.$message({
            type: 'error',
            showClose: true,
            message: error.message,
          });

        }

      }finally{

        this.saveIsLoading = false;

      }
      
    },
    removeListItem(item){

      if(!this.tourIsActive){

        MessageBox.confirm(
          'Are you sure you want to delete?',
          'Warning',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            type: 'warning',
          }
        ).then(async ()=>{
          
          let uuid = item.uid;

          function myfilter(value, uuid){

            let temp = []

            value.forEach(el=>{

              if(el.children && el.children.length > 0){

                el.children = myfilter(el.children, uuid);

              }

              if(el.uid != uuid){

                temp.push(el);

              }

            });

            return temp;

          }

          let temp = _.cloneDeep(this.currentNestableItems);

          this.currentNestableItems = myfilter(temp, uuid);

        }).catch(()=>{})

      }

    },
    handleLinkClick(item){

      if(!this.tourIsActive){

        if(item.isCustomLink && item.url.includes('https://')){

          window.open(item.url);

        }else{

          window.open('https://' + this.settings.domain + item.url);

        }

      }
      
    },

    //load product categories method starts here
    async handleCategoriesScrollEvent(event){

      let element = this.$refs.categoriesBox;

      let maxScrollTop = element.scrollHeight - element.clientHeight;

      if(element.scrollTop >= maxScrollTop - 100){

        if(this.categories.loading == false && this.categories.currentPage != this.categories.lastPage){

          this.loadProductCategories(this.categories.currentPage + 1);

        }

      }

    },
    async loadProductCategories(page){

      this.categories.loading = true;
      try{
        let res = await this.$axios.get("getAllCategories",{
          params:{
            offset: 10,
            page: page,
          }
        });
        if(res.data.status_code == "1003"){

          res.data.categories.data.forEach((element) =>{

            let uuid = uuidv4();

            let cat = {
              id:element.id,
              text: element.name,
              slug: element.slug,
              uid:uuid,
              isActive:element.isActive
            }

            this.categories.list.push(cat);

          });

          this.categories.currentPage = res.data.categories.current_page;
          this.categories.lastPage = res.data.categories.last_page;

        }
      }catch(error){

        this.$message({
          type: 'error',
          showClose: true,
          message: error.response ? error.response.data.message : error.message,
        });

      }finally{

        this.categories.loading = false;

      }

    },
    //load product categories method ends here

    //tour methods
    startHeaderNavigationTour(){

      this.$tours.headerNavigationTour.start();

      this.tourIsActive = true;

    },
    handleNextStep(currentStep){
    },
    handlePreviousStep(currentStep){
    },
    handleTourStop(){

      this.tourIsActive = false;

    },
    handleTourFinish(){

      this.tourIsActive = false;

    },
    handleTourSkip(){

      this.tourIsActive = false;

    },
  },
  mounted(){

    this.$refs.categoriesBox.addEventListener("scroll", this.handleCategoriesScrollEvent);

  },
  async beforeMount(){

    try{

      if(!this.storeHeaderNavigation || this.storeHeaderNavigation.length == 0){

        this.$store.dispatch("navigations_module/fetchNavigationRequest",{navigationName: 'main'});

      }else{

        this.nestableItems = this.storeHeaderNavigation;
        this.currentNestableItems = _.cloneDeep(this.nestableItems);

      }

      this.loadProductCategories(1);

    }catch(error){

      this.$message({
        type: 'error',
        showClose: true,
        message: error.response ? error.response.data.message : error.message,
      });

    }
      
  },
  beforeDestroy(){
    
  },
};
</script>
<style>
  .active-filter{
    border: 1px solid #1fc2c2;
    border-radius: 50px;
    padding: 0px 10px;
    text-align: center;
    color: #1fc2c2;
  }
  .nestable {
    position: relative;
  }
  .nestable-rtl {
    direction: rtl;
  }
  .nestable .nestable-list {
    margin: 0;
    padding: 0 0 0 40px;
    list-style-type: none;
  }
  .nestable-rtl .nestable-list {
    padding: 0 40px 0 0;
  }
  .nestable > .nestable-list {
    padding: 0;
  }
  .nestable-item,
  .nestable-item-copy {
    margin: 10px 0 0;
  }
  .nestable-item:first-child,
  .nestable-item-copy:first-child {
    margin-top: 0;
  }
  .nestable-item .nestable-list,
  .nestable-item-copy .nestable-list {
    margin-top: 10px;
  }
  .nestable-item {
    position: relative;
  }
  .nestable-item.is-dragging .nestable-list {
    pointer-events: none;
  }
  .nestable-item.is-dragging * {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .nestable-item.is-dragging:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(106, 127, 233, 0.274);
    border: 1px dashed rgb(73, 100, 241);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  .nestable-drag-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    pointer-events: none;
  }
  .nestable-rtl .nestable-drag-layer {
    left: auto;
    right: 0;
  }
  .nestable-drag-layer > .nestable-list {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    background-color: rgba(106, 127, 233, 0.274);
  }
  .nestable-rtl .nestable-drag-layer > .nestable-list {
    padding: 0;
  }
  .nestable [draggable="true"] {
    cursor: move;
  }
  .nestable-handle {
    display: inline;
  }
  .el-notification{
    z-index: 100000000 !important;
  }
  /* .swal2-container{
    z-index: 9999;
  } */
</style>